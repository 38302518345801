//@import '~antd/dist/antd.less'; // Import Ant Design styles by less entry
//@import "../node_modules/antd/lib/";

//$primary-color: #1890ff; // primary color for all components


// Global variables
$primary-color: #0c0c0c !important;
$bg: white;
$green-text: #2A6101;
$sgb-green: #286300;
$gray-text: #666666;
$gray-background: #F2F2F2;

$mobile-small: 599px;
$mobile-breakpoint: 768px;
$mobile-large: 860px; // TODO: move all instances of mobile-breakpoint to mobile-large
$max-width: 1146px;

// $min-size: minimum element $property size in $unit specified
// $max-size: maximum element $property size in $unit specified
// $min-width: the screen width in $units where you want to lock in the $min-size
// $max-width: the screen width in $units where you want to lock in the $max-size
// $property: the property of the element that you would like to scale/lock
// $unit: the units which all measurements are taken in

@mixin css-lock($min-size: 1, $max-size: 1.4, $min-width: 20, $max-width: 100, $property: font-size, $unit: rem) {
  #{$property}: calc(#{$min-size}#{$unit} + (#{$max-size} - #{$min-size}) * ((100vw - #{$min-width}#{$unit}) / (#{$max-width} - #{$min-width})));

  @media (max-width: #{$min-width}#{$unit}) {
    #{$property}: #{$min-size}#{$unit} !important;
  }

  @media (min-width: #{$max-width}#{$unit}) {
    #{$property}: #{$max-size}#{$unit} !important;
  }
}

// Hide products in menu when screen is small
.only-large-screen {
  display: block;

  @media only screen and (max-width: $mobile-large) {
    display: none;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  //height: 80px;
  height: 120px;

  background: transparent;
  transition: height 0.25s ease;

  .logo {
    height: 70px;
    margin: 5px 10px;

    img {
      object-fit: contain;
      height: 100%;
    }

    @media only screen and (max-width: $mobile-small) {
      width: 75px;
      overflow: hidden;
      flex-shrink: 0;
    }
  }

  .buttons {
    display: flex;
    flex-flow: wrap;
    a {
      margin: 0 20px;

      font-size: 12px;
      line-height: 30px;
      letter-spacing: 0.4px;
      font-weight: bold;
      text-transform: uppercase;
      color: $gray-text;

      &:hover {
        //color: black;
        color: $sgb-green;
        transition: color 0.25s ease;
      }
    }
  }

  .cart-icon {
    height: 40px;
    margin: 5px 10px;

    img {
      object-fit: contain;
      height: 100%;
    }
    img.in-progress {
      border: 1px rgb(24, 144, 255) solid;
      padding: -1px;
      border-radius: 5px;
    }
}

  &.affixed {
    height: 80px;
    background: white;
    -webkit-box-shadow: 0px 1px 1px 1px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 1px 1px 1px rgba(0,0,0,0.15);
    box-shadow: 0px 1px 1px 1px rgba(0,0,0,0.15);
  }
}

body {
  @include css-lock(14, 16, 360, 768, font-size, px);
  //font-size: 16px !important;
  font-family: Montserrat, sans-serif !important;
  color: $primary-color;
}

// Scalable content margin
.content {
  width: 90%;
  max-width: $max-width;
  margin: 0 auto;

  &.centered-text {
    text-align: center;
  }

  &.centered-headers {
    h1, h2, h3, h4, h5, h6 {
      text-align: center;
    }
  }

}

.italics {
  font-style: italic;
}

.nowrap {
  white-space: nowrap;
}

// Landing page styles
.cta {}

h1 {
  //margin: 50px 0 0 0;
  @include css-lock(20, 50, 360, 768, margin-top, px);

  @include css-lock(1.25, 3, 20, 80);
  font-weight: bold;
  color: $green-text;
}

h2 {
  @include css-lock(1, 1.5, 20, 80);
  font-weight: 500;
  color: $gray-text;
}

.linkedin-title {

  img {
    float: right;

    height: 45px !important;
    width: 45px !important;
    object-fit: contain;
  }
}

// Thick green divider
.divider {
  width: 90%;
  max-width: 1146px;

  @include css-lock(30, 60, 360, 768, margin-top, px);
  margin-right: auto;
  @include css-lock(30, 60, 360, 768, margin-bottom, px);
  margin-left: auto;

  border: 3.5px solid $sgb-green;

  opacity: 0.7;
}

// Landing page product display
.products {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px;

  @include css-lock(30, 60, 360, 768, margin-top, px);
  //@include css-lock(50, 100, 360, 768, margin-bottom, px);

  > a {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;


    @include css-lock(1, 1.25, 20, 80);

    img {
      display: block;
      @include css-lock(100, 200, 360, 768, height, px);
      width: auto;
      margin-bottom: 10px;

      object-fit: contain;
    }

    .green {
      color: green;
      font-weight: bold;
    }

    .yellow {
      color: darkgoldenrod;
      font-weight: bold;
    }

    .blue {
      color: blue;
      font-weight: bold;
    }

    .red {
      color: #e20000;
      font-weight: bold;
    }
  }

  @media only screen and (max-width: $mobile-breakpoint) {
    grid-template-columns: 1fr 1fr;
  }
}

// Same as in features
h2.green {
  @include css-lock(1, 2, 20, 80);
  color: $green-text;
  font-weight: 600;
}

.features {
  background: $gray-background;
}

.feature {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  @include css-lock(30, 60, 360, 768, padding-top, px);
  @include css-lock(30, 60, 360, 768, padding-bottom, px);

  //padding: 60px 0;

  .main-text {
    @include css-lock(14, 20, 360, 768, font-size, px);
    //font-size: 20px;

    h2 {
      @include css-lock(1, 2, 20, 80);
      color: $green-text;
      font-weight: 600;
    }

    &.small-text {
      font-size: inherit !important;
    }
  }

  .image {

    &.left {
      margin-right: 60px;
    }

    &.right {
      margin-left: 60px;
    }

    img {
      max-height: 600px;
      object-fit: contain;
      max-width: 400px;
    }
  }

  @media only screen and (max-width: $mobile-large) {
    flex-flow: column nowrap;

    .image {
      margin-left: 0 !important;
      margin-right: 0 !important;
      order: 1; // Always after text
    }

    img {
      //height: auto;
      width: 90%;
      //max-width: 300px;
      margin: 0 auto !important;
    }
  }
}

.team-member {
  display: flex;
  margin: 50px;

  img {
    //width: 250px;
    height: 300px;
    object-fit: contain;
  }
}


footer {
  padding: 40px;

  background: #2C2B2B;
  color: #BCBEC0;

  a {
    color: #BCBEC0;
  }

  .bar {
    color: #3e3e3e;
    font-weight: bolder;
  }

  .contact-info {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;

    margin: 0 0 20px 0;
    border-bottom: 2px solid #3e3e3e;
    padding-bottom: 20px;

    font-size: 20px;
    text-transform: uppercase;
  }

  .flex {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    img {
      height: 140px;
      object-fit: contain;
      margin: 0 40px 0 0;
    }

    h2 {
      font-size: 26px;
      margin: 0;
      color: #BCBEC0;
    }
  }

  h6 {
    margin-top: 10px;

    text-align: center;
    color: #BCBEC0;
  }

  @media only screen and (max-width: 1300px) {
    .contact-info > .very-large-only {
      display: none;
    }
  }

  @media only screen and (max-width: 900px) {
    .flex {
      flex-flow: column nowrap;

      img {
        margin: 0 0 20px 0;
      }
    }
  }

  @media only screen and (max-width: 700px) {
    .contact-info {
      flex-flow: column nowrap;
      height: auto;
      text-align: center;
      line-height: 40px;

      .large-only.bar {
        display: none;
      }
    }
  }
}

.scroll-to-bottom {
  display: flex;
  justify-content: center;

  > span {
    display: flex;
    justify-content: center;

    width: 50px;
    height: 50px;
    margin-bottom: -25px;

    border-radius: 50%;
    background: white;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);

    svg {
      margin-top: 17px;
    }

    &:hover {
      cursor: pointer;
    }
  }
}

// Antd override
.ant-btn-primary.ant-btn-lg {
  background-color: $sgb-green;
  border-color: $sgb-green;
}

.product-info {
  display: grid;
  grid-template-columns: 1fr 1fr;

  margin: -120px 0 0 0;
  padding: 120px 0 0 0;

  background: $gray-background;

  .images {
    display: flex;
    flex-flow: row nowrap;
    // align-items: end;
    flex-grow: 1;

    img {
      object-fit: contain;

      &.selected-image {
        border: 6px solid #eaeaea;
      }
    }

    .other-images {
      display: flex;
      flex-flow: column wrap;

      div {
        color: gray;
      }

      img {
        height: 100px;
        width: 100px;
        object-fit: contain;
        margin: 0 10px 40px 0;

        &:hover {
          cursor: pointer;
        }
      }
    }
    div.selected-image {
      @media only screen and (max-width: $mobile-small) {
        display: none;
      }
    }
  }

  .info {
    flex-grow: 1;
    padding: 40px 0 0 60px;

    @media only screen and (max-width: $mobile-small) {
      padding-left: 0;
    }

    .variant-select {
      margin: 0 20px 0 0;
    }

    .add-to-cart {
      display: block;
      margin: 20px 0 0 0;
    }
  }

  h1 {
    margin: 60px 0 0 0;
  }

}

.price {
  font-weight: 600;
  white-space: nowrap;

  &.padding {
    padding: 0 0 0 10px;
  }
}

.product-specs {
  p {
    word-break: break-word;
  }

  .specs {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    div {

    }

    @media only screen and (max-width: $mobile-breakpoint) {
      flex-flow: column nowrap;
    }
  }

  h3 {
    margin: 20px 0 10px 0;
  }

  h5 {
    margin: 10px 0 5px 0;
  }

}

.empty-space {
  height: 80px;
}

.checkout {
  display: block;
  margin: 20px 0 0 0;
}

.cart {
  display: grid;
  //grid-template-columns: 50px auto 100px 100px;
  grid-template-columns: 1fr 3fr 1fr 1fr;
  //flex-flow: row nowrap;

  .ant-input-number {
    min-width: 45px;
  }

  @media only screen and (max-width: $mobile-small) {
    grid-template-columns: 0fr 3fr 1fr 1fr;
    img {
      display: none;
    }
  }
}

.message {
  line-height: 40px;
}

// IE only
@media screen and (-ms-high-contrast: active), 
screen and (-ms-high-contrast: none) {

  .header {
    .logo {
      img {
        height: 60px;
      }
    }
    .cart-icon {
      img {
        height: 40px;
      }
    }
  }

  .feature {
    .main-text {
      max-width: 50%;
    }
    .image {
      max-width: 35%;
      height: auto;

      img {
        height: auto;
      }
    }
  }

  .products {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
  }
  
  .product-info {
    display: flex;
    flex-flow: row nowrap;
    .images {
      max-width: 50%;
      .other-images {
        max-width: 25%;

        img {
          height: auto;
        }
      }
      div.selected-image {
        max-width: 75%;
      }
    }
    .info {
      max-width: 50%;
      @media only screen and (max-width: $mobile-small) {
        padding-left: 30px;
      }
    }
  }
      
  .cart {
    display: flex;
    flex-flow: row wrap;
    // min-width: 840px;
    > * {
      flex-grow: 1;
    }
    > .description {
      flex-grow: 3;
      flex-basis: 55%;
    }
    > div {
      margin-right: 30px;
    }
    @media only screen and (max-width: $mobile-small) {
      > div.cart-image {
        margin-right: 0;
      }
    }
  }

  footer {
    .flex {
      div {
        max-width: 80%;
      }
    }
  }
}
